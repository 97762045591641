import React from 'react';

import withToastNotifications from 'dpl/decorators/withToastNotifications';
import ToastNotificationsList from 'dpl/components/ToastNotificationsList';

function ToastNotificationsListContainer(props) {
  return <ToastNotificationsList {...props} />;
}

export default withToastNotifications(ToastNotificationsListContainer);
