import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isEmpty } from 'dpl/shared/utils/object';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';

export default function ForgotPasswordForm({
  formState,
  formErrors,
  handleFormFieldChange,
  isCentered,
  buttonClassName,
  isFormSubmitting,
  handleFormSubmission,
  didFormSubmissionSucceed,
  didFormSubmissionFail
}) {
  function handleSubmit(e) {
    e.preventDefault();
    handleFormSubmission();
  }

  return (
    <div className="ForgotPasswordForm">
      {didFormSubmissionSucceed ? (
        <div className="ForgotPasswordForm__success">
          <h5 className={classnames('mb6', { tc: isCentered })}>
            Password reset link sent
          </h5>
          <p
            className={classnames('dark-gray mb12 fw-medium', {
              tc: isCentered
            })}
          >
            We&apos;ve sent a reset link to your email. Be sure to check your
            junk, spam, social, or other folders if you don&apos;t see it in
            your inbox.
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="ForgotPasswordForm__form">
            <h5 className={classnames('mb6', { tc: isCentered })}>
              Reset password
            </h5>
            <p
              className={classnames('dark-gray mb12 fw-medium', {
                tc: isCentered
              })}
            >
              Enter your email address and we&apos;ll send you a link to reset
              your password.
            </p>

            <div className="row mb12">
              <div className="col">
                <label htmlFor="email">
                  <p className="dark-gray mb2 f2">Email address</p>
                  <ErrorWrapper
                    errors={formErrors.email}
                    isShown={didFormSubmissionFail}
                  >
                    <input
                      name="email"
                      type="email"
                      className="w-100 br1 ba b--light-gray ph4 pv2"
                      value={formState.email || ''}
                      onChange={handleFormFieldChange}
                      placeholder="jane@highpeaksfarm.com"
                    />
                  </ErrorWrapper>
                </label>
              </div>
            </div>

            <div className="mb10">
              <button
                data-test-id="forgot_password_submit"
                disabled={isFormSubmitting || !isEmpty(formErrors)}
                type="submit"
                className={classnames(buttonClassName, {
                  'button--loading': isFormSubmitting
                })}
              >
                Send reset link
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  formState: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object.isRequired,
  handleFormSubmission: PropTypes.func.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  isCentered: PropTypes.bool,
  buttonClassName: PropTypes.string,
  isFormSubmitting: PropTypes.bool.isRequired,
  didFormSubmissionSucceed: PropTypes.bool.isRequired,
  didFormSubmissionFail: PropTypes.bool.isRequired
};

ForgotPasswordForm.defaultProps = {
  isCentered: false,
  buttonClassName: 'w-100 button button--primary'
};
