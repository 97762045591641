// Dumb file name, but this is where we put common, non-page-specific logic

import initReactApp from 'dpl/util/initReactApp';
import ToastNotificationsListContainer from 'dpl/containers/ToastNotificationsListContainer';
import UserAuthModalContainer from 'dpl/containers/UserAuthModalContainer';
import InternationalBreederModal from 'dpl/components/InternationalBreederModal';
import EmailConfirmationModal from 'dpl/email_confirmation/components/EmailConfirmationModal';
import CompactTrustSection from 'dpl/common/components/CompactTrustSection';

initReactApp({
  ToastNotificationsListContainer,
  UserAuthModalContainer,
  InternationalBreederModal,
  EmailConfirmationModal,
  CompactTrustSection
});
