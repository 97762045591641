import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { PARTNERSHIP } from 'dpl/constants/query_params';
import { USER_TYPES } from 'dpl/shared/constants/shared';
import { LETTER_TO_RESPONSIBLE_BREEDERS_PATH } from 'dpl/shared/constants/urls';
import SignupFormContainer from 'dpl/containers/SignupFormContainer';

import signupHands from 'dpl/assets/signup_hands.svg';

export default function UserAuthModalBreederSignupForm({
  onAuth,
  onLoginClick
}) {
  const partnership = useSelector(state => state.queryParams[PARTNERSHIP]);

  return (
    <div className="UserAuthModalBreederSignupForm">
      <div className="UserAuthModalBreederSignupForm__form ph5 ph8-lg ph18-lg relative">
        <img
          alt="heart with hands"
          src={signupHands}
          className="UserAuthModalBreederSignupForm__bannerImage absolute left-0 right-0 center"
        />
        <h6 className="tc mb6 heading-5-lg">Join as a Breeder</h6>
        <p className="mid-gray tc mb6">
          We take the safety of your information seriously. Your information is
          secure and never shared. &nbsp;
          <a
            className="underline"
            href={LETTER_TO_RESPONSIBLE_BREEDERS_PATH}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more
          </a>
        </p>
        <SignupFormContainer
          onAuth={onAuth}
          userType={USER_TYPES.BREEDER}
          partnership={partnership}
        />
      </div>
      <div className="pv6 tc bt b--light-gray">
        <span>Already have an account?</span>
        &nbsp;
        <a
          className="primary link--primary"
          href="javascript:void(0)"
          onClick={e => {
            e.preventDefault();
            onLoginClick(e);
          }}
        >
          Log in
        </a>
      </div>
    </div>
  );
}

UserAuthModalBreederSignupForm.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onLoginClick: PropTypes.func.isRequired
};
