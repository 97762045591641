/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React from 'react';
import PropTypes from 'prop-types';

import { USER_TYPES } from 'dpl/shared/constants/shared';
import SignupFormContainer from 'dpl/containers/SignupFormContainer';

export default function UserAuthModalSignupForm({
  onAuth,
  onBreederSignupClick
}) {
  return (
    <div className="UserAuthModalSignupForm">
      <div className="ph18-md pt12-md ph10">
        <h5 className="tc mb6">Sign up for Good Dog</h5>
        <SignupFormContainer onAuth={onAuth} userType={USER_TYPES.BUYER} />
      </div>
      <div className="pv6 tc bt b--light-gray">
        <span>Are you a breeder?</span>
        &nbsp;
        <a
          data-test-id="breeder_signup_button"
          className="primary link--primary"
          onClick={e => {
            e.preventDefault();
            onBreederSignupClick(e);
          }}
          role="button"
        >
          Join now
        </a>
      </div>
    </div>
  );
}

UserAuthModalSignupForm.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onBreederSignupClick: PropTypes.func.isRequired
};
