import React from 'react';
import { connect } from 'react-redux';

import { SIGNUP_FORM_TYPES } from 'dpl/shared/constants/shared';
import { pushParams, resetParams } from 'dpl/actions/query_params';
import UserAuthModal from 'dpl/components/UserAuthModal';

const { BREEDER_SIGNUP, FORGOT_PASSWORD, LOGIN, SIGNUP } = SIGNUP_FORM_TYPES;

function UserAuthModalContainer(props) {
  return <UserAuthModal {...props} />;
}

export default connect(({ queryParams }) => ({ queryParams }), {
  onBreederSignupClick() {
    return pushParams({ direct_signup: BREEDER_SIGNUP }, { hash: true });
  },
  clearParams() {
    return resetParams('direct_signup', { hash: true });
  },
  onLoginClick() {
    return pushParams({ direct_signup: LOGIN }, { hash: true });
  },
  onPasswordResetClick() {
    return pushParams({ direct_signup: FORGOT_PASSWORD }, { hash: true });
  },
  onSignupClick() {
    return pushParams({ direct_signup: SIGNUP }, { hash: true });
  }
})(UserAuthModalContainer);
