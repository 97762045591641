import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';
import { isMobileUA } from 'dpl/shared/utils';
import ToastNotificationsListItem from 'dpl/components/ToastNotificationsList/ToastNotificationsListItem';

const isMobile = isMobileUA();

export default function ToastNotificationsList({
  toastNotifications,
  removeToastNotification
}) {
  if (toastNotifications.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames('ToastNotificationsList mw-90 fixed z-max', {
        'bottom-1 left-1': isMobile,
        'bottom-2 left-2': !isMobile
      })}
    >
      <ul>
        {toastNotifications.map(tn => (
          <ToastNotificationsListItem
            key={tn.id}
            message={tn.message}
            onClose={() => removeToastNotification(tn)}
            type={tn.type}
          />
        ))}
      </ul>
    </div>
  );
}

ToastNotificationsList.propTypes = {
  toastNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.node.isRequired,
      type: PropTypes.oneOf(Object.values(TOAST_NOTIFICATIONS_TYPES))
    })
  ).isRequired,
  removeToastNotification: PropTypes.func.isRequired
};
