import React from 'react';
import { useSelector } from 'react-redux';

import Stars from 'dpl/components/Stars';
import TrustPilotStars from 'dpl/components/TrustPilotStars';
import SmartImage from 'dpl/components/SmartImage';
import { FEATURES } from 'dpl/shared/constants/features';
import isFeatureEnabled from 'dpl/util/isFeatureEnabled';

import googleSvg from 'dpl/assets/google_logo.svg';
import trustpilotSvg from 'dpl/assets/trustpilot_logo.svg';

const IS_OPS_FOOTER_GOOGLE_REVIEWS_ENABLED = isFeatureEnabled(
  FEATURES.OPS_FOOTER_GOOGLE_REVIEWS
);

export default function CompactTrustSection() {
  const {
    trustItems: {
      googleUrl,
      googleScore,
      googleRatingsCount,
      trustpilotUrl,
      trustpilotScore,
      trustpilotRatingsCount
    }
  } = useSelector(({ server }) => ({ trustItems: server.trustItems }));

  const showGoogle =
    IS_OPS_FOOTER_GOOGLE_REVIEWS_ENABLED && googleScore && googleRatingsCount;
  const showTrustpilot = trustpilotScore && trustpilotRatingsCount;

  if (!showGoogle && !showTrustpilot) {
    return null;
  }

  return (
    <div className="CompactTrustSection tc bg-near-white pt6 pb5">
      <div className="flex justify-center">
        {showGoogle && (
          <div className="ph4 ph6-l">
            <a href={googleUrl} rel="noopener noreferrer" target="_blank">
              <div className="flex justify-center flex-column flex-lg-row">
                <div className="mh4 mb1 mb0-l">
                  {googleScore} stars <br className="db dn-l" /> from{' '}
                  {googleRatingsCount} reviews
                </div>
                <div className="dn db-l">
                  <Stars
                    backgroundColor="#cccccc"
                    fullCount={Number(googleScore)}
                    fillColor="#FFA52F"
                    starWidth={20}
                    totalCount={5}
                    id="google"
                  />
                </div>
                <div className="mh4">
                  <SmartImage height="24px" lazy src={googleSvg} width="75px" />
                </div>
              </div>
            </a>
          </div>
        )}
        {showTrustpilot && (
          <div className="ph6">
            <a href={trustpilotUrl} rel="noopener noreferrer" target="_blank">
              <div className="flex justify-center flex-column flex-lg-row">
                <div className="mh4 mb1 mb0-l">
                  <span>
                    {trustpilotScore} stars <br className="db dn-l" /> from{' '}
                    {trustpilotRatingsCount} reviews
                  </span>
                </div>
                <div className="dn db-l pt1">
                  <TrustPilotStars
                    fullCount={Number(trustpilotScore)}
                    starWidth={20}
                    totalCount={5}
                    id="trustpilot"
                  />
                </div>
                <div className="mh4">
                  <SmartImage
                    height="26px"
                    lazy
                    src={trustpilotSvg}
                    width="99px"
                  />
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
