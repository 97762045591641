import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { capitalize } from 'dpl/shared/utils';
import Icon from 'dpl/common/components/Icon';

const VALID_TAG_NAMES = ['input', 'textarea', 'select'];

function isChildFocusable(children) {
  return VALID_TAG_NAMES.includes(children.props.Tag || children.type);
}

export default class ErrorWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    errors: PropTypes.arrayOf(PropTypes.node),
    isShown: PropTypes.bool,
    showDelay: PropTypes.number,
    newDesignSystemStyles: PropTypes.bool
  };

  static defaultProps = {
    errors: [],
    isShown: false,
    showDelay: 0,
    newDesignSystemStyles: false
  };

  state = { errors: [] };

  timeoutId = null;

  /**
   * Only display new errors when user clicks away from input
   */
  handleBlur = e => {
    const { children, errors } = this.props;

    if (typeof children.props.onBlur === 'function') {
      children.props.onBlur(e);
    }

    window.clearTimeout(this.timeoutId);
    this.setState({ errors });
  };

  componentDidUpdate(prevProps, prevState) {
    const { errors, isShown } = this.props;

    const errorsChanged =
      prevState.errors.length !== errors.length ||
      prevState.errors.some(e => !errors.includes(e));

    if (
      errorsChanged &&
      // parent forced
      (isShown ||
        // errors already displayed
        prevState.errors.length)
    ) {
      window.clearTimeout(this.timeoutId);

      if (!errors.length) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ errors: [] });
        return;
      }

      // debounced handler for setting state.errors
      // not using util's debounce because we need
      // to have a reference to the timeout to explicitly clear it
      this.timeoutId = window.setTimeout(() => {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ errors });
      }, this.props.showDelay);
    }
  }

  render() {
    const { children, newDesignSystemStyles } = this.props;
    const { errors } = this.state;
    const hasErrors = errors.length > 0;

    const childIsFocusable = isChildFocusable(children);

    const ERROR_TEXT_CLASSNAMES = newDesignSystemStyles
      ? 'flex inline-flex items-center f1 f2-sm fl default-color'
      : 'red f2';

    return (
      <div
        className={classnames('ErrorWrapper', {
          'ErrorWrapper--errored': hasErrors
        })}
      >
        {/* If child is not focusable, detect blur here. Browser support may be spotty though. */}
        <div
          className="relative z-2"
          onBlur={childIsFocusable ? undefined : this.handleBlur}
        >
          {
            // Place onBlur directly on the child element if the element is focusable
            childIsFocusable
              ? cloneElement(children, {
                  ...children.props,
                  onBlur: this.handleBlur
                })
              : children
          }
        </div>
        <div
          className={classnames('ErrorWrapper__errorsContainer relative z-1', {
            mt1: errors.length > 0
          })}
        >
          {errors.map(e => (
            <span key={e} className={ERROR_TEXT_CLASSNAMES}>
              {newDesignSystemStyles && (
                <Icon
                  name="fetch-union"
                  className="red mr1"
                  width="16px"
                  height="16px"
                />
              )}
              {typeof e === 'string' ? capitalize(e) : e}
            </span>
          ))}
        </div>
      </div>
    );
  }
}
