import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetParams } from 'dpl/actions/query_params';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import { INTERNATIONAL_BREEDER_SIGNUP } from 'dpl/constants/query_params';
import { BREEDER_CENTER_PATH } from 'dpl/shared/constants/urls';

import internationalDogImage from 'dpl/assets/international_dog.jpg';

export default function InternationalBreederModal() {
  const isInternationalBreederModalOpen = useSelector(
    state => state.queryParams[INTERNATIONAL_BREEDER_SIGNUP]
  );
  const dispatch = useDispatch();
  function onCloseClick() {
    return dispatch(resetParams(INTERNATIONAL_BREEDER_SIGNUP));
  }

  if (!isInternationalBreederModalOpen) {
    return null;
  }

  return (
    <MiniOverlayModal
      className="InternationalBreederModal"
      onCloseClick={onCloseClick}
    >
      <div className="InternationalBreederModal">
        <img src={internationalDogImage} alt="sad dog" />
        <div className="ph20-md ph8 pt8 pb14 tc">
          <h5 className="mb6 f5">
            We&apos;re so sorry, but Good Dog is not in your country yet!
          </h5>
          <p className="mb6">
            Thanks so much for your interest in Good Dog! Please check your
            inbox for more information about how you can stay up to date on our
            international expansion plans. We can&apos;t wait to expand into
            your country!&nbsp;
            <span role="img" aria-label="smile">
              🙂
            </span>
          </p>
          <p className="mb8">
            In the meantime, you can access our free content and resources that
            are available to breeders worldwide in our Good Breeder Center!
          </p>
          <a
            href={BREEDER_CENTER_PATH}
            className="button button--primary button--large pv3 fw-medium w-100"
          >
            Visit the Good Breeder Center
          </a>
        </div>
      </div>
    </MiniOverlayModal>
  );
}
