import React from 'react';
import PropTypes from 'prop-types';

import { makeSequence } from 'dpl/shared/utils';

export default function TrustPilotStars({
  fullCount,
  id,
  starWidth,
  totalCount
}) {
  const defId = [
    'Trust_Stars__star',
    id,
    fullCount,
    starWidth,
    totalCount
  ].join('|');

  return (
    <div className="TrustPilotStars">
      <div className="flex nowrap f0">
        {makeSequence(totalCount).map(idx => {
          const starDefId = `${defId}-${idx}`;

          return (
            <span key={idx}>
              <svg viewBox="0 0 50 46" width={starWidth}>
                <defs>
                  <linearGradient
                    id={starDefId}
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset={Math.min(1, fullCount - idx)}
                      style={{ stopColor: '#219653' }}
                    />
                    <stop offset="0" style={{ stopColor: '#dcdcdc' }} />
                  </linearGradient>
                </defs>

                <path
                  className=""
                  d="M0 46.330002h46.375586V0H0z"
                  fill={`url(#${starDefId})`}
                />
                <path
                  className=""
                  d="M39.533936 19.711433L13.230239 38.80065l3.838216-11.797827L7.02115 19.711433h12.418975l3.837417-11.798624 3.837418 11.798624h12.418975zM23.2785 31.510075l7.183595-1.509576 2.862114 8.800152L23.2785 31.510075z"
                  fill="#FFF"
                />
              </svg>
            </span>
          );
        })}
      </div>
    </div>
  );
}

TrustPilotStars.propTypes = {
  fullCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  starWidth: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};
