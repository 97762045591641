import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { JOIN_AS_SHELTER_PATH } from 'dpl/shared/constants/urls';
import LoginFormContainer from 'dpl/containers/LoginFormContainer';

export default function UserAuthModalLoginForm({
  onAuth,
  onPasswordResetClick,
  onBreederSignupClick,
  onSignupClick,
  loginOnly
}) {
  return (
    <div className="UserAuthModalLoginForm">
      <div
        className={classnames('ph18-md pt12-md ph10', {
          pb10: loginOnly
        })}
      >
        <h5 className="tc mb6">Log in to Good Dog</h5>
        <LoginFormContainer
          hideForgotPassword={!onPasswordResetClick}
          onPasswordResetClick={onPasswordResetClick}
          onAuth={onAuth}
        />
      </div>
      {!loginOnly && (
        <div className="pv6 tc bt b--light-gray">
          <div className="mb4">
            <span>Join Good Dog</span>&nbsp;
          </div>
          <div className="mb4">
            <button
              data-test-id="breeder_signup_button"
              className="primary link--primary"
              onClick={onBreederSignupClick}
              type="button"
            >
              I&apos;m a breeder
            </button>
          </div>
          <div className="mb4">
            <a className="primary link--primary" href={JOIN_AS_SHELTER_PATH}>
              I&apos;m a shelter or rescue
            </a>
          </div>
          <div>
            <button
              data-test-id="signup_button"
              className="primary link--primary"
              onClick={onSignupClick}
              type="button"
            >
              I&apos;m looking for a dog
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

UserAuthModalLoginForm.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onPasswordResetClick: PropTypes.func,
  onBreederSignupClick: PropTypes.func,
  onSignupClick: PropTypes.func,
  loginOnly: PropTypes.bool
};

UserAuthModalLoginForm.defaultProps = {
  loginOnly: false,
  onBreederSignupClick: null,
  onSignupClick: null,
  onPasswordResetClick: null
};
