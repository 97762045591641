import React from 'react';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import withFormCapabilities from 'dpl/form/decorators/withFormCapabilities';
import ForgotPasswordForm from 'dpl/components/ForgotPasswordForm';

function ForgotPasswordFormContainer(props) {
  return <ForgotPasswordForm {...props} />;
}

export default compose(
  __DEPRECATED_defineResources(() => ({
    sessionData: {
      url: '/api/users/password.json',
      auto: false,
      actions: {
        submit: json => ({
          method: 'POST',
          url: '/api/users/password.json',
          body: json
        })
      }
    }
  })),
  withFormCapabilities(() => ({}), {
    mapPropsAndStateToValidationSchema: (_, { email }) => ({
      email: email ? CONSTRAINT_TYPES.EMAIL : CONSTRAINT_TYPES.TEXT
    }),
    onSubmit: ({ sessionData }, formStateForSubmission) =>
      sessionData.submit({ user: formStateForSubmission })
  })
)(ForgotPasswordFormContainer);
