/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React from 'react';
import PropTypes from 'prop-types';

import ForgotPasswordFormContainer from 'dpl/containers/ForgotPasswordFormContainer';

export default function UserAuthModalForgotPasswordForm({ onLoginClick }) {
  return (
    <div className="UserAuthModalForgotPasswordForm">
      <div className="ph18-md pt12-md ph10">
        <ForgotPasswordFormContainer isCentered />
      </div>
      <div className="pv6 tc bt b--light-gray">
        <a
          data-test-id="forgot_password_close_button"
          className="primary link--primary"
          onClick={e => {
            e.preventDefault();
            onLoginClick(e);
          }}
          role="button"
        >
          Back to log in
        </a>
      </div>
    </div>
  );
}

UserAuthModalForgotPasswordForm.propTypes = {
  onLoginClick: PropTypes.func.isRequired
};
