import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SIGNUP_FORM_TYPES, USER_TYPES } from 'dpl/shared/constants/shared';
import { EVENT_CATEGORIES } from 'dpl/constants/analytics';
import { get } from 'dpl/shared/utils/object';
import { sendAhoyAndGAEvents } from 'dpl/util/analytics';
import { addToQuery } from 'dpl/util/queryString';
import postLoginRedirect from 'dpl/util/postLoginRedirect';
import postSignupRedirect from 'dpl/util/postSignupRedirect';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import UserAuthModalLoginForm from 'dpl/components/UserAuthModal/UserAuthModalLoginForm';
import UserAuthModalSignupForm from 'dpl/components/UserAuthModal/UserAuthModalSignupForm';
import UserAuthModalBreederSignupForm from 'dpl/components/UserAuthModal/UserAuthModalBreederSignupForm';
import UserAuthModalForgotPasswordForm from 'dpl/components/UserAuthModal/UserAuthModalForgotPasswordForm';
import { INTERNATIONAL_BREEDER_SIGNUP } from 'dpl/constants/query_params';

const { BREEDER_SIGNUP, FORGOT_PASSWORD, SIGNUP } = SIGNUP_FORM_TYPES;

export default class UserAuthModal extends Component {
  static propTypes = {
    clearParams: PropTypes.func.isRequired,
    onBreederSignupClick: PropTypes.func.isRequired,
    onLoginClick: PropTypes.func.isRequired,
    onPasswordResetClick: PropTypes.func.isRequired,
    onSignupClick: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({
      direct_signup: PropTypes.string
    }),
    loginOnly: PropTypes.bool
  };

  static defaultProps = {
    queryParams: {},
    loginOnly: false
  };

  sendGAEventIfNeeded() {
    if (this.props.queryParams.direct_signup === BREEDER_SIGNUP) {
      sendAhoyAndGAEvents('open', {
        event_category: EVENT_CATEGORIES.BREEDER_OPEN_SIGNUP_MODAL
      });
    }
  }

  componentDidMount() {
    this.sendGAEventIfNeeded();
  }

  componentDidUpdate() {
    this.sendGAEventIfNeeded();
  }

  render() {
    const {
      clearParams,
      onBreederSignupClick,
      onLoginClick,
      onPasswordResetClick,
      onSignupClick,
      queryParams,
      loginOnly
    } = this.props;

    const { direct_signup: directSignup } = queryParams;

    if (typeof directSignup !== 'string') {
      return null;
    }

    return (
      <MiniOverlayModal className="UserAuthModal" onCloseClick={clearParams}>
        {(() => {
          // buyer signup
          if (directSignup === SIGNUP) {
            return (
              <UserAuthModalSignupForm
                onAuth={() => {
                  clearParams();
                  return window.location.reload();
                }}
                onBreederSignupClick={onBreederSignupClick}
              />
            );
          }

          // breeder signup
          if (directSignup === BREEDER_SIGNUP) {
            return (
              <UserAuthModalBreederSignupForm
                onAuth={json => {
                  const isInternationalBreeder = get(
                    json,
                    'data.user.international_breeder'
                  );
                  clearParams();

                  if (isInternationalBreeder) {
                    window.location = `?${addToQuery(
                      { [INTERNATIONAL_BREEDER_SIGNUP]: true },
                      window.location.search
                    )}`;
                    return;
                  }

                  postSignupRedirect(USER_TYPES.BREEDER);
                }}
                onLoginClick={onLoginClick}
              />
            );
          }

          // forgot password
          if (directSignup === FORGOT_PASSWORD) {
            return (
              <UserAuthModalForgotPasswordForm onLoginClick={onLoginClick} />
            );
          }

          // universal login
          return (
            <UserAuthModalLoginForm
              loginOnly={loginOnly}
              onAuth={json => {
                if (get(json, 'data.data.buyer')) {
                  clearParams();
                  return window.location.reload();
                }

                // TODO: the assumption on buyer for a reload is not always true.
                // can we instead of doing this, do a server redirect when
                // reloading the page, signed in as a breeder? what page is this
                // used for?
                return postLoginRedirect(USER_TYPES.BREEDER);
              }}
              onPasswordResetClick={onPasswordResetClick}
              onSignupClick={onSignupClick}
              onBreederSignupClick={onBreederSignupClick}
            />
          );
        })()}
      </MiniOverlayModal>
    );
  }
}
