import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import Icon from 'dpl/common/components/Icon';
import { resetParams } from 'dpl/actions/query_params';
import { EMAIL_CONFIRMED } from 'dpl/constants/query_params';

export default function EmailConfirmationModal() {
  const isOpen = useSelector(state => state.queryParams[EMAIL_CONFIRMED]);
  const dispatch = useDispatch();
  function onCloseClick() {
    return dispatch(resetParams(EMAIL_CONFIRMED));
  }

  if (!isOpen) {
    return null;
  }

  return (
    <MiniOverlayModal
      className="EmailConfirmationModal"
      title="Your email address has been confirmed"
      onCloseClick={onCloseClick}
    >
      <div className="ph20-md ph8 pt8 pb10 tc">
        <div className="dib mb5 pv10 ph10 relative br-100 bg-lightest-purple">
          <Icon
            className="stone-700 center mb6 transform-center-all"
            height="40px"
            name="shield-locked"
            width="40px"
          />
        </div>
        <h5 className="mb6 f5">Your email address has been confirmed</h5>
      </div>
    </MiniOverlayModal>
  );
}
