import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';
import Icon from 'dpl/common/components/Icon';

const { ERROR, INFO, SUCCESS } = TOAST_NOTIFICATIONS_TYPES;

export default class ToastNotificationsListItem extends Component {
  static propTypes = {
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(TOAST_NOTIFICATIONS_TYPES))
  };

  static defaultProps = {
    type: INFO
  };

  state = {
    isVisible: false
  };

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({ isVisible: true });
    });
  }

  render() {
    const { message, onClose, type } = this.props;

    const { isVisible } = this.state;

    return (
      <div
        className={classnames('ToastNotificationsListItem mb3 transition', {
          'o-0': !isVisible,
          'o-100': isVisible
        })}
      >
        <div className="pv4 ph4 pl1 br1 relative bg-black white">
          <div
            className={classnames(
              'h-100 pl1 br1 br--left absolute top-0 left-0',
              {
                'bg-red': type === ERROR,
                'bg-green': type === SUCCESS,
                'bg-yellow': ![ERROR, SUCCESS].includes(type)
              }
            )}
          />
          <div className="flex">
            <div className="flex-auto">{message}</div>
            <div className="ml2">
              <button onClick={onClose} type="button">
                <Icon height="18px" name="close" width="18px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
